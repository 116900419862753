import Props from '@common/Props.interface'
import UniversalProps from '@common/UniversalProps.interface'
import delve from 'dlv'

import Footer from './global/Footer'
import Header from './global/Header'
import PreviewBanner from './global/PreviewBanner'
import Seo from './seo'

interface LayoutProps extends Props, UniversalProps {}

const Layout = ({ children, globalData, pageData, origin, preview = false }: LayoutProps) => {
  return (
    <>
      <Seo
        seo={delve(pageData, 'attributes.seo')}
        fallbackTitle={delve(pageData, 'attributes.title')}
        origin={origin}
      />
      {preview && <PreviewBanner />}
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Header {...globalData} pageData={pageData} />
      <main className="relative grow">{children}</main>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Footer {...globalData} pageData={pageData} />
    </>
  )
}

export default Layout
