import Props from '@common/Props.interface'
import { Size, Theme } from '@components/shared'
import Icon from '@icons/Icon'
import ReactMarkdown from 'react-markdown'

export interface AttributionProps extends Props {
  attribution: string
}

const Attribution = ({ className = '', attribution }: AttributionProps) => {
  return (
    <div className={`mx-auto flex items-center space-x-2 p-5 text-xs ${className}`}>
      <Icon icon="heart" theme={Theme.GREEN} size={Size.SMALLER} />

      <ReactMarkdown>{attribution}</ReactMarkdown>
    </div>
  )
}

export default Attribution
