import GlobalProps from '@common/GlobalProps.interface'
import Button from '@components/blocks/Button'
import { Theme } from '@components/shared'
import ActiveLink from '@components/shared/ActiveLink'
import LogoFull from '@components/shared/LogoFull'
import LogoSparse from '@components/shared/LogoSparse'
import { relationAdapter } from '@utils/adapters'
import classNames from '@utils/classNames'
import Link from 'next/link'

import SlideOverMenu from './SlideOverMenu'

const LOGO_THEME_MAPS: Record<Theme, string> = {
  [Theme.ORANGE]: 'text-orange-500',
  [Theme.GREEN]: 'text-green-500',
}

const TEXT_THEME_MAPS: Record<Theme, string> = {
  [Theme.ORANGE]: 'text-gray-700',
  [Theme.GREEN]: 'text-gray-700',
}

const HOVER_THEME_MAPS: Record<Theme, string> = {
  [Theme.ORANGE]: 'hover:text-orange-500',
  [Theme.GREEN]: 'hover:text-green-500',
}

const ACTIVE_THEME_MAPS: Record<Theme, string> = {
  [Theme.ORANGE]: 'text-orange-500',
  [Theme.GREEN]: 'text-green-500',
}

const Header = ({ header, menu, pageData }: GlobalProps) => {
  const { theme } = pageData.attributes

  return (
    <header className="fixed inset-0 z-40 flex h-header-mobile items-center justify-between bg-white bg-opacity-75 px-5 backdrop-blur-xl md:h-header md:px-7.5">
      <div className="flex shrink justify-start">
        <Link href="/" aria-label="Home">
          {/* FIXME: Unify logos into single component */}
          <LogoSparse
            className={classNames('hidden h-auto w-28 md:block', LOGO_THEME_MAPS[theme!])}
          />
          <LogoFull
            className={classNames('block h-auto w-28 md:hidden', LOGO_THEME_MAPS[theme!])}
          />
        </Link>
      </div>

      {header?.links && (
        <nav
          className={classNames(
            'relative mx-auto flex hidden space-x-10 text-base font-bold md:flex',
            TEXT_THEME_MAPS[theme!],
          )}
        >
          {header.links
            .filter((link) => link !== null && link.label !== null)
            .map((link) => {
              // eslint-disable-next-line no-param-reassign
              link.page = relationAdapter(link.page)
              // eslint-disable-next-line no-param-reassign
              link.href = link.href ?? link.page?.slug ?? ''

              return (
                <ActiveLink
                  href={link.href}
                  aria-label={link.label}
                  key={link.label}
                  className={classNames(HOVER_THEME_MAPS[theme!])}
                  activeClassName={classNames(ACTIVE_THEME_MAPS[theme!])}
                >
                  {link.label}
                </ActiveLink>
              )
            })}
        </nav>
      )}

      <div className="flex gap-5 items-center">
        {header?.cta && <Button {...header.cta} theme={header.cta.theme ?? theme} />}
        {menu && <SlideOverMenu theme={theme} menu={menu} />}
      </div>
    </header>
  )
}

export default Header
