/* eslint-disable react/no-unknown-property */
import SEO from '@common/SEO.interface'
import SocialLink from '@common/SocialLink.interface'
import { getStrapiMedia } from '@utils/strapi'
import delve from 'dlv'
import Head from 'next/head'

interface SeoProps {
  seo: SEO
  fallbackTitle: string
  origin?: string
}

const Seo = ({ seo, fallbackTitle, origin = '' }: SeoProps) => {
  const isAlternativeOrigin = !(process.env.NEXT_PUBLIC_APP_URL?.startsWith(origin) ?? true)
  const metaTitle = delve(seo, 'metaTitle')
  const metaImage = delve(seo, 'metaImage')
  const metaRobots = delve(seo, 'metaRobots')
  const metaSocial = delve(seo, 'metaSocial')
  const structuredData = delve(seo, 'structuredData')
  const preventIndexing = delve(seo, 'preventIndexing')
  const metaDescription = delve(seo, 'metaDescription')

  const metaSocialTwitter = metaSocial?.find((item: SocialLink) => item.socialNetwork === 'Twitter')

  return (
    <Head>
      <title>{metaTitle ?? fallbackTitle}</title>
      <meta name="description" content={metaDescription} key="description" />

      <meta name="twitter:card" content="summary" />
      {metaSocialTwitter && (
        <>
          <meta
            data-hid="twitter:title"
            name="twitter:title"
            property="twitter:title"
            content={metaSocialTwitter.title}
          />
          <meta
            data-hid="twitter:description"
            name="twitter:description"
            property="twitter:description"
            content={metaSocialTwitter.description}
          />
          <meta
            data-hid="twitter:image"
            name="twitter:image"
            property="twitter:image"
            content={
              getStrapiMedia(delve(metaSocialTwitter.image, 'data.attributes.url')) ?? undefined
            }
          />
          <meta
            data-hid="twitter:image:alt"
            name="twitter:image:alt"
            property="twitter:image:alt"
            content={delve(metaSocialTwitter.image, 'data.attributes.alternativeText')}
          />
        </>
      )}

      <meta
        prefix="og: http://ogp.me/ns#"
        data-hid="og:title"
        name="og:title"
        property="og:title"
        content={metaTitle}
      />
      <meta
        prefix="og: http://ogp.me/ns#"
        data-hid="og:description"
        name="og:description"
        property="og:description"
        content={metaDescription}
      />
      <meta
        prefix="og: http://ogp.me/ns#"
        data-hid="og:image"
        name="og:image"
        property="og:image"
        content={delve(metaImage, 'data.attributes.url')}
      />
      <meta
        prefix="og: http://ogp.me/ns#"
        data-hid="og:image:alt"
        name="og:image:alt"
        property="og:image:alt"
        content={delve(metaImage, 'data.attributes.alternativeText')}
      />

      {!(preventIndexing || isAlternativeOrigin) && (
        <meta name="robots" content={metaRobots ?? 'robots.txt'} />
      )}

      {(preventIndexing || isAlternativeOrigin) && (
        <>
          <meta name="robots" content="noindex,nofollow" />
          <meta name="googlebot" content="noindex,nofollow" />
        </>
      )}
      <script type="application/ld+json">{structuredData}</script>
    </Head>
  )
}

export default Seo
