import { Size } from '@components/shared'
import classNames from '@utils/classNames'

import { IconProps, Icons, IconVariant, SIZE_MAPS, THEME_MAPS, VARIANT_MAPS } from '.'

export interface IconLoaderProps extends IconProps {
  icon: Icons
}

// FIXME: Remove classname
const Icon = ({ icon, theme, variant, size, style, className = '' }: IconLoaderProps) => {
  const themeClassName = theme !== undefined ? THEME_MAPS[theme] : null
  const sizeClassName = size !== undefined ? SIZE_MAPS[size] : null
  const variantClassName = variant !== undefined ? VARIANT_MAPS[variant] : null
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMinYMin meet"
      className={classNames(themeClassName, variantClassName, sizeClassName, className)}
      style={style}
    >
      <use xlinkHref={`#${icon}`} />
    </svg>
  )
}

Icon.size = Size
Icon.variant = IconVariant

export default Icon
