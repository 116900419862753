import Props from '@common/Props.interface'
import { Theme } from '@components/shared'
import classNames from '@utils/classNames'
import ReactMarkdown from 'react-markdown'

export interface TaglineProps extends Props {
  primary: string
  secondary?: string | null
}

const THEME_MAPS: Record<Theme, string> = {
  [Theme.ORANGE]: 'bg-orange-500',
  [Theme.GREEN]: 'bg-green-500',
}

const Tagline = ({ id, className, primary, secondary = null, theme }: TaglineProps) => {
  const hasSecondary = secondary !== null
  const themeClassName = theme !== undefined ? THEME_MAPS[theme] : 'bg-black'

  const primaryTagline = () => {
    return (
      <div className={`${hasSecondary ? 'lg:basis-1/2' : 'w-full'} flex align-middle`}>
        <div className={classNames('w-2 flex-none rounded-full', themeClassName)} />
        <h1 className="py-3 px-5 text-2xl font-bold xs:text-3xl md:text-5xl">{primary}</h1>
      </div>
    )
  }

  const secondaryTagline = () => {
    if (hasSecondary) {
      return (
        <div className="basis-1/2 font-light leading-relaxed lg:align-middle">
          <ReactMarkdown className="prose">{secondary}</ReactMarkdown>
        </div>
      )
    }
    return null
  }

  return (
    <div
      className={`flex w-full flex-col items-start justify-between gap-5 lg:flex-row lg:items-center lg:gap-0 ${className}`}
      id={id}
    >
      {primaryTagline()}
      {secondaryTagline()}
    </div>
  )
}

export default Tagline
