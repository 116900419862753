import { Size, Theme } from '@components/shared'

import { IconProps, IconVariant } from './IconProps.interface'

const THEME_MAPS: Record<Theme, string> = {
  [Theme.ORANGE]: 'text-orange-500',
  [Theme.GREEN]: 'text-green-500',
}

const SIZE_MAPS: Record<Size, string> = {
  [Size.SMALLER]: 'h-5 w-5',
  [Size.SMALL]: 'h-8 w-8',
  [Size.MEDIUM]: 'h-14 w-14',
  [Size.LARGE]: 'h-20 w-20',
  [Size.LARGER]: 'h-24 w-24',
}

const VARIANT_MAPS: Record<IconVariant, string> = {
  [IconVariant.GRAYSCALE]: 'grayscale',
}

type Icons =
  | 'arrow-down'
  | 'arrow-left'
  | 'arrow-right'
  | 'arrow-up'
  | 'bars'
  | 'camera-view-finder'
  | 'chevron-down'
  | 'chevron-left'
  | 'chevron-right'
  | 'chevron-up'
  | 'circle-check'
  | 'circle-down'
  | 'circle-left'
  | 'circle-right'
  | 'circle-up'
  | 'computer-mouse-scrollwheel'
  | 'email'
  | 'envelope'
  | 'euro-sign'
  | 'face-icicles'
  | 'facebook'
  | 'file-invoice'
  | 'fire-flame-simple'
  | 'forward'
  | 'handshake'
  | 'heart'
  | 'heart-circle-bolt'
  | 'helmet-safety'
  | 'house-signal'
  | 'images'
  | 'instagram'
  | 'layer-group'
  | 'linkedin'
  | 'list-check'
  | 'lock'
  | 'magnifying-glass'
  | 'magnifying-glass-plus'
  | 'medal'
  | 'people-group'
  | 'phone'
  | 'plug-circle-bolt'
  | 'plug-circle-check'
  | 'star'
  | 'temperature-high'
  | 'user-helmet-safety'
  | 'volume-high'
  | 'whatsapp'
  | 'wrench'
  | 'xmark-large'

export { type IconProps, type Icons, IconVariant, SIZE_MAPS, THEME_MAPS, VARIANT_MAPS }
