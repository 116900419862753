import Props from '@common/Props.interface'
import { Theme } from '@components/shared'
import classNames from '@utils/classNames'
import isNullOrUndefined from '@utils/isNullOrUndefined'

enum Variant {
  LIGHT = 'LIGHT',
}

export enum Position {
  PAGE_BACKGROUND = 'PAGE_BACKGROUND',
  BACKGROUND = 'BACKGROUND',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  TOP = 'TOP',
}

interface SwirlProps extends Props {
  variant?: Variant | null
  rotate?: number
  position?: Position
}

const THEME_MAPS: Record<Theme, string> = {
  [Theme.ORANGE]: 'text-orange-500',
  [Theme.GREEN]: 'text-green-500',
} as const

const VARIANT_MAPS: Record<Variant, string> = {
  [Variant.LIGHT]: 'brightness-125',
}

// FIXME: Make these positions more generic
const POSITION_MAPS: Record<Position, string> = {
  [Position.PAGE_BACKGROUND]: 'bg-swirl',
  [Position.BACKGROUND]:
    'absolute -bottom-8 -right-8 h-full origin-left rotate-90 scale-75 overflow-hidden',
  [Position.LEFT]: 'relative h-full left-[45%] w-1/2 object-contain my-auto',
  [Position.RIGHT]: 'relative h-full mt-[4%] ml-[8%] w-1/2 object-contain my-auto',
  [Position.TOP]: 'absolute inset-y-0 right-0 w-full object-contain my-auto',
} as const

const Swirl = ({
  theme = Theme.ORANGE,
  variant = null,
  position,
  rotate = -90,
  className,
}: SwirlProps) => {
  const variantClassName = !isNullOrUndefined(variant) ? VARIANT_MAPS[variant!] : null
  const positionClassName = !isNullOrUndefined(position) ? POSITION_MAPS[position!] : null
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 490.443 1008.084"
      className={classNames(THEME_MAPS[theme], variantClassName, positionClassName, className)}
    >
      <g transform={`translate(516.448 -5076.415) rotate(${rotate})`}>
        <path
          d="M-5816.015-26.9c-2.237-37.95,25-78.069,62.405-90.734,12.621-4.274,26.408-6.1,39.792-6.921,31.61-1.948,63.448-1.079,94.918-4.154,54.947-5.369,105.521-24.766,152.209-53.9,95.646-59.682,154.8-145.381,178.792-255.028,5.568-25.452,6.762-51.859,10.09-78.818h96.065c-.822,15.2-1.1,30.219-2.529,45.122-7.7,80.076-32.561,154.537-77.01,221.619-76.156,114.934-181.882,187.537-317.6,214.226-30.9,6.075-62.913,7.723-94.5,8.731-44.677,1.426-89.435.372-134.156.345C-5810.2-26.407-5812.859-26.705-5816.015-26.9Z"
          transform="translate(105.329)"
          fill="currentColor"
        />
        <path
          d="M-5452.516-515.754c1.132,22.163-6.525,40.8-19.172,57.414-20.726,27.234-49.342,38.324-82.859,38.912-28.663.5-57.487-.341-85.947,2.415-73.919,7.159-140.031,34.892-198.085,81.017-75.422,59.923-122.714,137.586-142.217,231.7-4.556,21.993-5.636,44.749-7.547,67.219-.6,7.071-2.131,9.955-9.87,9.816-25.67-.464-51.354-.162-77.031-.2-2.666,0-5.329-.3-9.061-.521,0-9.016-.487-17.676.08-26.268,3.548-53.828,14.295-106.121,34.936-156.107,48.33-117.035,129.064-203.929,243.382-258.942,63.6-30.6,130.754-46.991,201.635-46.947q71.143.046,142.285-.065C-5459.046-516.3-5456.108-515.971-5452.516-515.754Z"
          transform="translate(0 0.057)"
          fill="currentColor"
        />
        <path
          d="M-5947.368-27.3c2.754-21.557,3.982-41.722,8.091-61.284,11.207-53.333,35.059-100.53,72.5-140.5,44.632-47.643,99-78.01,163.739-87.01,29.431-4.091,59.613-4.214,89.429-3.874,101.511,1.161,183.339-76.915,193.726-172.181.62-5.684,1.529-11.381,1.548-17.075.02-5.609,2.434-7.249,7.7-7.217q39.431.232,78.864.018c5.274-.024,7.495,1.543,7.3,7.332-2.4,71.34-26.368,134.07-73.563,188.014-40.909,46.759-91.385,77.537-151.99,90.49-21.654,4.629-44.209,5.45-66.424,6.972-25.837,1.77-52.242-.382-77.541,4.061-70.291,12.342-116.879,55.068-144.23,119.869a157.3,157.3,0,0,0-12.795,60.614c-.049,8.475-2.321,12.239-11.906,12C-5890.06-27.728-5917.232-27.3-5947.368-27.3Z"
          transform="translate(53.824 0.005)"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}

Swirl.variant = Variant
Swirl.position = Position

export default Swirl
