import { Menu } from '@common/Menu.interface'
import Props from '@common/Props.interface'
import Button from '@components/blocks/Button'
import Tagline from '@components/blocks/Tagline'
import { Size, Theme } from '@components/shared'
import ActiveLink from '@components/shared/ActiveLink'
import { Transition } from '@headlessui/react'
import Icon from '@icons/Icon'
import { relationAdapter } from '@utils/adapters'
import classNames from '@utils/classNames'

export interface SlideOverProps extends Props {
  opened: boolean
  closeHandler: () => void
  menu: Menu
}

const HOVER_THEME_MAPS: Record<Theme, string> = {
  [Theme.ORANGE]: 'hover:text-orange-500',
  [Theme.GREEN]: 'hover:text-green-500',
}

const ACTIVE_THEME_MAPS: Record<Theme, string> = {
  [Theme.ORANGE]: 'text-orange-500',
  [Theme.GREEN]: 'text-green-500',
}

const SlideOver = ({
  className = '',
  theme = Theme.GREEN,
  menu,
  opened,
  closeHandler,
}: SlideOverProps) => {
  return (
    <div
      className={`pointer-events-none fixed inset-0 z-50 h-screen w-screen overflow-hidden ${className}`}
      aria-labelledby="slide-over-title"
      role="dialog"
      aria-modal="true"
    >
      <Transition show={opened}>
        <Transition.Child
          enter="transition ease-in-out duration-300 transition-opacity"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition ease-in-out duration-300 transition-opacity"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="absolute inset-0 h-screen bg-gray-500 bg-opacity-75" />
        </Transition.Child>

        <Transition.Child
          enter="transform transition-all ease-in-out duration-300"
          enterFrom="translate-x-full"
          enterTo="translate-x-0"
          leave="transform transition-all ease-in-out duration-300"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
        >
          <div className="pointer-events-auto absolute inset-y-0 right-0 flex h-screen w-screen max-w-xl flex-col items-stretch bg-gray-200 p-20 shadow-xl">
            <div className="absolute right-7.5 top-7.5">
              <button
                type="button"
                className="rounded-md text-black hover:text-gray-700"
                onClick={closeHandler}
              >
                <span className="sr-only">Close panel</span>
                <Icon icon="xmark-large" size={Size.SMALLER} />
              </button>
            </div>
            <div className="my-auto">
              <div className="flex flex-col items-start space-y-5">
                <Tagline
                  primary={menu.label}
                  theme={theme}
                  id="slide-over-title"
                  className="px-0"
                />
                {menu.links && (
                  <ul className="mb-10 flex flex-col space-y-3 text-2xl font-bold">
                    {menu.links
                      .filter((link) => link !== null && link.label !== null)
                      .map((link) => {
                        // eslint-disable-next-line no-param-reassign
                        link.page = relationAdapter(link.page)
                        // eslint-disable-next-line no-param-reassign
                        link.href = link.href ?? link.page?.slug ?? ''

                        return (
                          <li key={link.href}>
                            <ActiveLink
                              href={link.href}
                              aria-label={link.label}
                              className={classNames(HOVER_THEME_MAPS[theme!])}
                              activeClassName={classNames(ACTIVE_THEME_MAPS[theme!])}
                              onClick={closeHandler}
                            >
                              {link.label}
                            </ActiveLink>
                          </li>
                        )
                      })}
                  </ul>
                )}
                {menu.cta && (
                  <div>
                    <Button
                      theme={menu.cta.theme ?? theme}
                      {...menu.cta}
                      className="mt-6"
                      onClick={closeHandler}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </Transition.Child>
      </Transition>
    </div>
  )
}

export default SlideOver
