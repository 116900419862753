import Props from '@common/Props.interface'
import classNames from '@utils/classNames'
import isNullOrUndefined from '@utils/isNullOrUndefined'

enum Width {
  DEFAULT = 'DEFAULT',
  SMALLER = 'SMALLER',
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  FULL = 'FULL',
}

enum Spacing {
  SMALLER = 'SMALLER',
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

enum Variant {
  FIRST = 'FIRST',
}

enum Alignment {
  START = 'START',
  CENTER = 'CENTER',
  END = 'END',
}

export interface SectionProps extends Props {
  width?: Width
  spacing?: Spacing
  variant?: Variant
  columns?: number
  vertical?: boolean
  alignment?: Alignment
}

const WIDTH_MAPS: Record<Width, string> = {
  [Width.SMALLER]: 'max-w-2xl',
  [Width.SMALL]: 'max-w-4xl',
  [Width.MEDIUM]: 'max-w-5xl',
  [Width.FULL]: 'no-padding',
  [Width.DEFAULT]: 'max-w-7xl',
} as const

const SPACING_MAPS: Record<Spacing, string> = {
  [Spacing.SMALLER]: 'gap-2.5 md:gap-5',
  [Spacing.SMALL]: 'gap-5 md:gap-10',
  [Spacing.MEDIUM]: 'gap-10 md:gap-15',
  [Spacing.LARGE]: 'gap-20 md:gap-30',
} as const

const VARIANT_MAPS: Record<Variant, string> = {
  [Variant.FIRST]: 'py-20 md:py-12',
} as const

const ALIGNMENT_MAPS: Record<Alignment, string> = {
  [Alignment.START]: 'items-start',
  [Alignment.CENTER]: 'items-center',
  [Alignment.END]: 'items-end',
} as const

const Section = ({
  children,
  width = Width.DEFAULT,
  spacing = Spacing.MEDIUM,
  columns,
  variant,
  alignment = Alignment.START,
  vertical = true,
  className = '',
}: SectionProps) => {
  const flowDirection = vertical ? 'col' : 'row'
  const gridDirection = vertical ? 'row' : 'col'

  const variantClassName = !isNullOrUndefined(variant) ? VARIANT_MAPS[variant!] : null
  const spacingClassName = !isNullOrUndefined(spacing) ? SPACING_MAPS[spacing!] : null
  const containerClassName = !isNullOrUndefined(columns)
    ? `grid md:grid-${gridDirection}s-${columns}`
    : `flex flex-${flowDirection}`
  const widthClassName = !isNullOrUndefined(width) ? WIDTH_MAPS[width!] : null
  const alignmentClassName = !isNullOrUndefined(alignment) ? ALIGNMENT_MAPS[alignment!] : null

  return (
    <div
      className={classNames(
        'section',
        containerClassName,
        spacingClassName,
        variantClassName,
        widthClassName,
        alignmentClassName,
        className,
      )}
    >
      {children}
    </div>
  )
}

Section.width = Width
Section.spacing = Spacing
Section.alignment = Alignment
Section.variant = Variant

export default Section
