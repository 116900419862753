import Props from '@common/Props.interface'

const LogoSparse = ({ className = '' }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="109.433"
      height="23.098"
      viewBox="0 0 109.433 23.098"
      className={className}
    >
      <g transform="translate(-600.476 428.323)">
        <path
          id="Path_982"
          data-name="Path 982"
          d="M497.818,437.559a9.265,9.265,0,0,1-4.449-1.006,7.183,7.183,0,0,1-2.891-2.777,7.943,7.943,0,0,1-1.02-4.039,8.535,8.535,0,0,1,.567-3.146,7.57,7.57,0,0,1,1.587-2.508,7.063,7.063,0,0,1,2.423-1.644,7.961,7.961,0,0,1,3.075-.581,7.639,7.639,0,0,1,2.99.567,6.7,6.7,0,0,1,2.324,1.616,7.278,7.278,0,0,1,1.5,2.465,8.138,8.138,0,0,1,.468,3.118l-.028,1.19h-11.9l-.652-2.636h8.332l-.51.567v-.539a1.863,1.863,0,0,0-.326-1.091,2.161,2.161,0,0,0-.864-.723,2.814,2.814,0,0,0-1.219-.255,3.067,3.067,0,0,0-1.6.383,2.5,2.5,0,0,0-.978,1.091,4.369,4.369,0,0,0,.127,3.656,3.142,3.142,0,0,0,1.389,1.275,5.061,5.061,0,0,0,2.253.454,4.788,4.788,0,0,0,1.545-.227,5.366,5.366,0,0,0,1.431-.794l2.437,3.429a9.9,9.9,0,0,1-2,1.261,9.418,9.418,0,0,1-2,.68A9.571,9.571,0,0,1,497.818,437.559Z"
          transform="translate(131.906 -842.784)"
          fill="currentColor"
        />
        <path
          id="Path_983"
          data-name="Path 983"
          d="M570.165,437.657a5.858,5.858,0,0,1-3.358-1.006,6.939,6.939,0,0,1-2.352-2.777,9.09,9.09,0,0,1-.864-4.039,9.269,9.269,0,0,1,.85-4.081,6.82,6.82,0,0,1,2.353-2.763,6.07,6.07,0,0,1,3.458-1.006,6.264,6.264,0,0,1,1.927.283,4.6,4.6,0,0,1,1.53.822,6.324,6.324,0,0,1,1.134,1.219,6.939,6.939,0,0,1,.794,1.474l-1.077-.057V422.3h5.187V437.4h-5.3v-3.684l1.162.057a5.49,5.49,0,0,1-.751,1.531,5.333,5.333,0,0,1-1.2,1.233,5.695,5.695,0,0,1-3.486,1.119Zm1.5-4.336a2.862,2.862,0,0,0,1.559-.411,2.656,2.656,0,0,0,1.006-1.2,4.556,4.556,0,0,0,.354-1.871,4.635,4.635,0,0,0-.354-1.9,2.663,2.663,0,0,0-1.006-1.2,2.868,2.868,0,0,0-1.559-.411,2.8,2.8,0,0,0-1.545.411,2.609,2.609,0,0,0-.978,1.2,4.808,4.808,0,0,0-.34,1.9,4.725,4.725,0,0,0,.34,1.871,2.6,2.6,0,0,0,.978,1.2A2.789,2.789,0,0,0,571.667,433.321Z"
          transform="translate(74.353 -842.882)"
          fill="currentColor"
        />
        <path
          id="Path_984"
          data-name="Path 984"
          d="M646.845,415.716v-4.478h10.4v4.478Zm2.494,10.628V407.412h5.271v18.932Z"
          transform="translate(9.717 -831.569)"
          fill="currentColor"
        />
        <path
          id="Path_985"
          data-name="Path 985"
          d="M706.9,393.913a3.384,3.384,0,0,1-2.239-.666,2.323,2.323,0,0,1-.794-1.885,2.378,2.378,0,0,1,.808-1.856,3.217,3.217,0,0,1,2.225-.723,3.4,3.4,0,0,1,2.225.666,2.57,2.57,0,0,1-.014,3.755A3.271,3.271,0,0,1,706.9,393.913Zm-2.636,17.968V396.775h5.272v15.106Z"
          transform="translate(-34.554 -817.106)"
          fill="currentColor"
        />
        <path
          id="Path_986"
          data-name="Path 986"
          d="M746.151,439.625l-6.292-15.106h5.867L749.1,435.8l-1.36.028,3.4-11.308h5.838l-6.6,15.106Z"
          transform="translate(-62.495 -844.85)"
          fill="currentColor"
        />
        <path
          id="Path_987"
          data-name="Path 987"
          d="M826.928,437.559a9.265,9.265,0,0,1-4.45-1.006,7.182,7.182,0,0,1-2.891-2.777,7.943,7.943,0,0,1-1.02-4.039,8.536,8.536,0,0,1,.567-3.146,7.567,7.567,0,0,1,1.587-2.508,7.063,7.063,0,0,1,2.423-1.644,7.961,7.961,0,0,1,3.075-.581,7.639,7.639,0,0,1,2.99.567,6.7,6.7,0,0,1,2.324,1.616,7.283,7.283,0,0,1,1.5,2.465,8.139,8.139,0,0,1,.468,3.118l-.028,1.19h-11.9l-.652-2.636h8.332l-.51.567v-.539a1.862,1.862,0,0,0-.326-1.091,2.161,2.161,0,0,0-.864-.723,2.814,2.814,0,0,0-1.219-.255,3.067,3.067,0,0,0-1.6.383,2.5,2.5,0,0,0-.978,1.091,4.368,4.368,0,0,0,.128,3.656,3.139,3.139,0,0,0,1.389,1.275,5.063,5.063,0,0,0,2.253.454,4.787,4.787,0,0,0,1.544-.227,5.367,5.367,0,0,0,1.431-.794l2.437,3.429a9.894,9.894,0,0,1-2,1.261,9.415,9.415,0,0,1-2,.68A9.569,9.569,0,0,1,826.928,437.559Z"
          transform="translate(-123.6 -842.784)"
          fill="currentColor"
        />
        <path
          id="Path_988"
          data-name="Path 988"
          d="M396.063,423.2V403.357h5.612V423.2Zm2.126-7.425.028-4.932h12.952v4.932Zm9.891,7.425V403.357h5.612V423.2Z"
          transform="translate(204.413 -828.42)"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}

export default LogoSparse
