import Props from '@common/Props.interface'

const LogoFull = ({ className = '' }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="200"
      height="59.848"
      viewBox="0 0 200 59.848"
      className={className}
    >
      <g id="Group_3113" data-name="Group 3113" transform="translate(-92 -3763)">
        <path
          id="Path_973"
          data-name="Path 973"
          d="M500.114,441.871a11.81,11.81,0,0,1-5.671-1.282,9.156,9.156,0,0,1-3.685-3.54,10.125,10.125,0,0,1-1.3-5.148,10.879,10.879,0,0,1,.722-4.01,9.648,9.648,0,0,1,2.023-3.2,9,9,0,0,1,3.089-2.095,10.147,10.147,0,0,1,3.919-.741,9.736,9.736,0,0,1,3.811.722,8.536,8.536,0,0,1,2.962,2.059,9.277,9.277,0,0,1,1.914,3.143,10.37,10.37,0,0,1,.6,3.974l-.036,1.517H493.286l-.831-3.359h10.62l-.65.722v-.686a2.375,2.375,0,0,0-.415-1.391,2.755,2.755,0,0,0-1.1-.921,3.587,3.587,0,0,0-1.553-.325,3.91,3.91,0,0,0-2.041.488,3.186,3.186,0,0,0-1.246,1.391,5.569,5.569,0,0,0,.162,4.66,4.005,4.005,0,0,0,1.77,1.625,6.452,6.452,0,0,0,2.872.578,6.1,6.1,0,0,0,1.969-.289,6.839,6.839,0,0,0,1.824-1.011l3.107,4.371a12.627,12.627,0,0,1-2.547,1.608,12,12,0,0,1-2.547.867A12.2,12.2,0,0,1,500.114,441.871Z"
          transform="translate(-310.32 3364.194)"
          fill="currentColor"
        />
        <path
          id="Path_974"
          data-name="Path 974"
          d="M571.971,441.962a7.467,7.467,0,0,1-4.281-1.282,8.845,8.845,0,0,1-3-3.54,11.585,11.585,0,0,1-1.1-5.148,11.815,11.815,0,0,1,1.083-5.2,8.691,8.691,0,0,1,3-3.522,7.736,7.736,0,0,1,4.407-1.282,7.98,7.98,0,0,1,2.456.361,5.864,5.864,0,0,1,1.951,1.047,8.064,8.064,0,0,1,1.445,1.554,8.846,8.846,0,0,1,1.011,1.879l-1.373-.072v-4.371h6.611v19.254h-6.755v-4.7l1.481.072a7,7,0,0,1-.957,1.951,6.789,6.789,0,0,1-1.535,1.571,7.258,7.258,0,0,1-4.443,1.427Zm1.915-5.527a3.648,3.648,0,0,0,1.987-.524,3.384,3.384,0,0,0,1.282-1.535,5.806,5.806,0,0,0,.452-2.384,5.907,5.907,0,0,0-.452-2.42,3.394,3.394,0,0,0-1.282-1.536,3.656,3.656,0,0,0-1.987-.523,3.562,3.562,0,0,0-1.969.523,3.325,3.325,0,0,0-1.246,1.536,6.128,6.128,0,0,0-.433,2.42,6.022,6.022,0,0,0,.433,2.384,3.316,3.316,0,0,0,1.246,1.535A3.555,3.555,0,0,0,573.885,436.434Z"
          transform="translate(-363.32 3364.104)"
          fill="currentColor"
        />
        <path
          id="Path_975"
          data-name="Path 975"
          d="M646.845,418v-5.708H660.1V418Zm3.179,13.547V407.412h6.719v24.131Z"
          transform="translate(-422.842 3374.522)"
          fill="currentColor"
        />
        <path
          id="Path_976"
          data-name="Path 976"
          d="M707.736,395.322a4.315,4.315,0,0,1-2.854-.849,2.961,2.961,0,0,1-1.012-2.4,3.031,3.031,0,0,1,1.03-2.366,4.1,4.1,0,0,1,2.836-.921,4.333,4.333,0,0,1,2.835.849,3.276,3.276,0,0,1-.018,4.787A4.169,4.169,0,0,1,707.736,395.322Zm-3.36,22.9V398.97H711.1v19.254Z"
          transform="translate(-463.611 3387.841)"
          fill="currentColor"
        />
        <path
          id="Path_977"
          data-name="Path 977"
          d="M747.879,443.773l-8.02-19.254h7.478l4.3,14.377-1.734.036,4.335-14.414h7.442l-8.417,19.254Z"
          transform="translate(-489.341 3362.292)"
          fill="currentColor"
        />
        <path
          id="Path_978"
          data-name="Path 978"
          d="M829.224,441.871a11.81,11.81,0,0,1-5.672-1.282,9.156,9.156,0,0,1-3.685-3.54,10.124,10.124,0,0,1-1.3-5.148,10.879,10.879,0,0,1,.723-4.01,9.644,9.644,0,0,1,2.023-3.2A9,9,0,0,1,824.4,422.6a10.147,10.147,0,0,1,3.919-.741,9.736,9.736,0,0,1,3.811.722,8.535,8.535,0,0,1,2.962,2.059,9.28,9.28,0,0,1,1.915,3.143,10.372,10.372,0,0,1,.6,3.974l-.036,1.517H822.4l-.831-3.359h10.621l-.651.722v-.686a2.373,2.373,0,0,0-.415-1.391,2.754,2.754,0,0,0-1.1-.921,3.586,3.586,0,0,0-1.553-.325,3.908,3.908,0,0,0-2.041.488,3.189,3.189,0,0,0-1.246,1.391,5.568,5.568,0,0,0,.163,4.66,4,4,0,0,0,1.77,1.625,6.454,6.454,0,0,0,2.872.578,6.1,6.1,0,0,0,1.969-.289,6.837,6.837,0,0,0,1.824-1.011l3.107,4.371a12.622,12.622,0,0,1-2.547,1.608,12,12,0,0,1-2.547.867A12.191,12.191,0,0,1,829.224,441.871Z"
          transform="translate(-545.612 3364.194)"
          fill="currentColor"
        />
        <g id="Group_3064" data-name="Group 3064" transform="translate(92 3763)">
          <path
            id="Path_979"
            data-name="Path 979"
            d="M197.323,341.365a13.133,13.133,0,0,0-2.017,4.248,19.448,19.448,0,0,0-.471,4.5c-.079,12.837-.043,25.675-.035,38.513a2.575,2.575,0,0,0,2.013,2.784,8.914,8.914,0,0,0,3.535.079,2.619,2.619,0,0,0,2.158-2.867c.024-2.711-.016-5.423.016-8.134a5.46,5.46,0,0,1,4.4-5.49,5.37,5.37,0,0,1,5.98,3.038,6.667,6.667,0,0,1,.5,2.46c.063,2.612-.048,5.228.038,7.839.212,6.439-3.567,10-8.29,11.509a20.459,20.459,0,0,1-14.181-.443,10.655,10.655,0,0,1-7.142-10.628c0-11.564.115-23.129-.043-34.691-.1-7.281,4.264-10.685,9.3-12.065A18.567,18.567,0,0,1,197.323,341.365Z"
            transform="translate(-183.783 -341.259)"
            fill="currentColor"
          />
          <path
            id="Path_980"
            data-name="Path 980"
            d="M279.056,389.991q0-.8,0-1.6V375.011q.009-11.02-.019-22.041a10.479,10.479,0,0,0-6.785-10.28,19.974,19.974,0,0,0-15.36-.318c-4.474,1.679-7.223,4.879-7.441,9.808-.084,1.929-.087,3.859-.074,5.789.016,2.34-.386,4.789,1.076,6.816a5.558,5.558,0,0,0,5.338,2.167,5.406,5.406,0,0,0,4.494-5.289c.031-2.741,0-5.485.016-8.229a2.854,2.854,0,0,1,2.43-3.092,7.087,7.087,0,0,1,2.694-.029,2.847,2.847,0,0,1,2.6,3.228c.022,12.21.067,24.42.022,36.628,0,1.831.031,3.659.039,5.487a4.6,4.6,0,0,0,.259,1.35,5.444,5.444,0,0,0,1.936,2.744,5.646,5.646,0,0,0,4.239,1.023,5.448,5.448,0,0,0,4.527-5.328C279.066,393.626,279.058,391.808,279.056,389.991Z"
            transform="translate(-230.651 -340.992)"
            fill="currentColor"
          />
        </g>
        <path
          id="Path_981"
          data-name="Path 981"
          d="M396.063,428.644V403.357h7.153v25.287Zm2.709-9.464.036-6.286h16.509v6.286Zm12.608,9.464V403.357h7.153v25.287Z"
          transform="translate(-243.549 3377.421)"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}

export default LogoFull
