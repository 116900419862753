import PageAttributes from '@common/PageAttributes.interface'
import Props from '@common/Props.interface'
import Swirl from '@components/shared/graphics/Swirl'
import classNames from '@utils/classNames'

export interface PageProps extends PageAttributes, Props {}

const Page = ({ children, showFlare, theme, className = '' }: PageProps) => {
  // const spacing = 'gap-18 pb-18 md:gap-38 md:pb-38'
  // const spacing = 'space-y-18 pb-18 md:space-y-38 md:pb-38'
  const spacing = ''
  return (
    <div className={classNames('flex w-full flex-col', spacing, className)}>
      {showFlare && <Swirl position={Swirl.position.PAGE_BACKGROUND} theme={theme} />}
      {children}
    </div>
  )
}

export default Page
