import { Contact } from '@common/Contact.interface'
import Props from '@common/Props.interface'
import { Theme } from '@components/shared'
import RoundIcon from '@icons/RoundIcon'
import themeStyles from '@styles/markdown-styles.module.scss'
import classNames from '@utils/classNames'
import Link from 'next/link'
import ReactMarkdown from 'react-markdown'

export interface CtaContactProps extends Contact, Props {}

const THEME_MAPS: Record<Theme, string> = {
  [Theme.ORANGE]: 'text-orange-500 hover:text-orange-400',
  [Theme.GREEN]: 'text-green-500 hover:text-green-400',
}

const fillVariables = (input: string, variables: any) => {
  let text = input
  Object.keys(variables).forEach((key) => {
    text = text.replaceAll(`{${key}}`, variables[key])
  })
  return text
}

const CtaContactUs = ({
  heading,
  text,
  theme = Theme.ORANGE,
  social_networks,
}: CtaContactProps) => {
  const variables = Object.fromEntries([
    ...(social_networks ?? []).map((social) => [`${social.network}.uri`, social.uri]),
    ...(social_networks ?? []).map((social) => [
      `${social.network}.label`,
      social.label ?? social.uri,
    ]),
  ])

  const replacedText = fillVariables(text, variables)
  return (
    <div className="flex flex-col items-center space-x-2 bg-gray-300 py-26">
      <h2 className="heading-lg text-gray-700">{heading}</h2>
      <div className="mt-5 px-7.5 text-center 2xl:px-0">
        <ReactMarkdown className={classNames('prose', themeStyles[theme])}>
          {replacedText}
        </ReactMarkdown>
      </div>
      <div className="mt-10 grid gap-y-8 xs:grid-flow-col-dense xs:gap-8">
        {social_networks &&
          social_networks.map((social) => {
            return (
              <Link
                href={social.uri}
                target="_blank"
                className={classNames(THEME_MAPS[theme])}
                key={social.uri}
              >
                <RoundIcon variant={RoundIcon.variant.LIGHT_GRAY} icon={social.network} />
              </Link>
            )
          })}
      </div>
    </div>
  )
}

export default CtaContactUs
