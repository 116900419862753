import { Footer } from '@common/Footer.interface'
import Props from '@common/Props.interface'
import { Size, Theme } from '@components/shared'
import ActiveLink from '@components/shared/ActiveLink'
import LogoFull from '@components/shared/LogoFull'
import Icon from '@icons/Icon'
import { relationAdapter } from '@utils/adapters'
import classNames from '@utils/classNames'
import Link from 'next/link'
import ReactMarkdown from 'react-markdown'

export interface SitemapProps extends Props {
  footer: Footer
}

const THEME_MAPS: Record<Theme, string> = {
  [Theme.ORANGE]: 'bg-orange-500 text-white',
  [Theme.GREEN]: 'bg-green-500 text-white',
}

const HOVER_THEME_MAPS: Record<Theme, string> = {
  [Theme.ORANGE]: 'hover:text-orange-200',
  [Theme.GREEN]: 'hover:text-green-200',
}

const ACTIVE_THEME_MAPS: Record<Theme, string> = {
  [Theme.ORANGE]: 'text-orange-200',
  [Theme.GREEN]: 'text-green-200',
}

const Sitemap = ({ theme = Theme.ORANGE, footer }: SitemapProps) => {
  return (
    <div className={classNames('flex items-center space-x-2 py-20 ', THEME_MAPS[theme])}>
      <div className="mx-auto grid w-3/4 max-w-7xl grid-cols-1 gap-16 text-white lg:grid-cols-4 lg:gap-10">
        <div className="order-1 lg:col-span-3">
          <div className="grid grid-cols-1 gap-10 md:grid-cols-3">
            {footer.columns.map((column) => (
              <div className="flex flex-col space-y-5" key={column.label}>
                <span className="text-lg font-bold">{column.label}</span>
                <ul className="flex flex-col space-y-2 text-sm">
                  {column.links
                    .filter((link) => link != null && link.label !== null)
                    .map((link) => {
                      // eslint-disable-next-line no-param-reassign
                      link.page = relationAdapter(link.page)
                      // eslint-disable-next-line no-param-reassign
                      link.href = link.href ?? link.page?.slug ?? ''

                      return (
                        <ActiveLink
                          href={link.href}
                          key={link.href}
                          aria-label={link.label}
                          className={classNames(HOVER_THEME_MAPS[theme!])}
                          activeClassName={classNames(ACTIVE_THEME_MAPS[theme!])}
                        >
                          {link.label}
                        </ActiveLink>
                      )
                    })}
                </ul>
              </div>
            ))}
            <div className="flex flex-col space-y-5">
              <span className="text-lg font-bold">{footer.contact.label}</span>
              <div className="flex flex-col space-y-5 text-sm">
                {footer.contact.social_networks.map((social) => (
                  <div className="flex items-center space-x-5" key={social.uri}>
                    <Icon icon={social.network} size={Size.SMALLER} />
                    <Link href={social.uri}>
                      {social.label && <span className="font-bold">{social.label}</span>}
                    </Link>
                  </div>
                ))}
              </div>
              <div className="ml-11 text-sm">
                <ReactMarkdown>{footer.contact.text}</ReactMarkdown>
              </div>
            </div>
          </div>
        </div>
        <div className="order-2 lg:order-first">
          <LogoFull className="flex-none" />
        </div>
        <div className="order-3 flex space-x-5">
          {footer.social_networks.map((social) => (
            <Link href={social.uri} key={social.uri}>
              <Icon icon={social.network} size={Size.SMALL} />
            </Link>
          ))}
        </div>
        <div className="order-last self-center text-sm lg:col-span-3 lg:col-start-2">
          <ReactMarkdown>{footer.footerText}</ReactMarkdown>
        </div>
      </div>
    </div>
  )
}

export default Sitemap
