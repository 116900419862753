/* eslint-disable react/button-has-type */
import ILink from '@common/Link.interface'
import { Size, Theme } from '@components/shared'
import { ButtonProps as CommonButtonProps } from '@forms/buttons/Button'
import Icon from '@icons/Icon'
import { Icons } from '@icons/index'
import { relationAdapter } from '@utils/adapters'
import classNames from '@utils/classNames'
import isNullOrUndefined from '@utils/isNullOrUndefined'
import Link from 'next/link'

enum Variant {
  PRIMARY = 'PRIMARY',
  INLINE = 'INLINE',
  PRIMARY_ON_DESKTOP_INLINE_ON_MOBILE = 'PRIMARY_ON_DESKTOP_INLINE_ON_MOBILE',
}

enum IconPosition {
  START = 'START',
  END = 'END',
}

enum ResponsivenessStyle {
  ICON_ONLY = 'ICON_ONLY',
  TEXT_ONLY = 'TEXT_ONLY',
  ICON_AND_TEXT = 'ICON_AND_TEXT',
}

export interface ButtonProps extends CommonButtonProps {
  link: ILink
  icon?: Icons
  variant?: Variant
  iconPosition?: IconPosition
  type?: 'button' | 'submit' | 'reset' | undefined
  onMobile?: ResponsivenessStyle
  onDesktop?: ResponsivenessStyle
}

const VARIANT_MAPS: Record<Variant, string> = {
  [Variant.PRIMARY]: 'justify-center rounded-full py-3 px-5 gap-3',
  [Variant.INLINE]: 'gap-3',
  [Variant.PRIMARY_ON_DESKTOP_INLINE_ON_MOBILE]:
    'gap-3 md:justify-center md:rounded-full md:py-3 md:px-5',
} as const

const ICON_MOBILE_MAPS: Record<ResponsivenessStyle, string> = {
  [ResponsivenessStyle.ICON_ONLY]: 'inline-block',
  [ResponsivenessStyle.TEXT_ONLY]: 'hidden',
  [ResponsivenessStyle.ICON_AND_TEXT]: 'inline-block',
} as const

const ICON_DESKTOP_MAPS: Record<ResponsivenessStyle, string> = {
  [ResponsivenessStyle.ICON_ONLY]: 'md:inline-block',
  [ResponsivenessStyle.TEXT_ONLY]: 'md:hidden',
  [ResponsivenessStyle.ICON_AND_TEXT]: 'md:inline-block',
} as const

const TEXT_MOBILE_MAPS: Record<ResponsivenessStyle, string> = {
  [ResponsivenessStyle.ICON_ONLY]: 'hidden',
  [ResponsivenessStyle.TEXT_ONLY]: 'inline-block',
  [ResponsivenessStyle.ICON_AND_TEXT]: 'inline-block',
} as const

const TEXT_DESKTOP_MAPS: Record<ResponsivenessStyle, string> = {
  [ResponsivenessStyle.ICON_ONLY]: 'md:hidden',
  [ResponsivenessStyle.TEXT_ONLY]: 'md:inline-block',
  [ResponsivenessStyle.ICON_AND_TEXT]: 'md:inline-block',
} as const

const THEME_VARIANT_MAPS: Record<Theme, Record<Variant, string>> = {
  [Theme.GREEN]: {
    [Variant.PRIMARY]: 'bg-green-500 hover:bg-green-400 text-white hover:text-gray-100',
    [Variant.INLINE]: 'text-green-500 hover:text-green-400',
    [Variant.PRIMARY_ON_DESKTOP_INLINE_ON_MOBILE]:
      'text-green-500 hover:text-green-400 md:bg-green-500 md:hover:bg-green-400 md:text-white md:hover:text-gray-100',
  },
  [Theme.ORANGE]: {
    [Variant.PRIMARY]: 'bg-orange-500 hover:bg-orange-400 text-white hover:text-gray-100',
    [Variant.INLINE]: 'text-orange-500 hover:text-orange-400',
    [Variant.PRIMARY_ON_DESKTOP_INLINE_ON_MOBILE]:
      'text-orange-500 hover:text-orange-400 md:bg-orange-500 md:hover:bg-orange-400 md:text-white md:hover:text-gray-100',
  },
} as const

const Button = ({
  className,
  icon,
  variant = Variant.PRIMARY,
  theme,
  link,
  iconPosition = IconPosition.START,
  type = 'button',
  onMobile = ResponsivenessStyle.ICON_AND_TEXT,
  onDesktop = ResponsivenessStyle.ICON_AND_TEXT,
  onClick,
}: ButtonProps) => {
  if (link) {
    // eslint-disable-next-line no-param-reassign
    link.page = relationAdapter(link.page)
    // eslint-disable-next-line no-param-reassign
    link.href = link.href ?? link.page?.slug ?? ''
  }

  const variantClassName = !isNullOrUndefined(variant) ? VARIANT_MAPS[variant] : null
  const themeVariantClassName = !isNullOrUndefined(theme)
    ? THEME_VARIANT_MAPS[theme!.toUpperCase() as Theme][variant]
    : null
  const iconClassName = classNames(
    !isNullOrUndefined(onMobile) ? ICON_MOBILE_MAPS[onMobile] : null,
    !isNullOrUndefined(onDesktop) ? ICON_DESKTOP_MAPS[onDesktop] : null,
  )
  const textClassName = classNames(
    !isNullOrUndefined(onMobile) ? TEXT_MOBILE_MAPS[onMobile] : null,
    !isNullOrUndefined(onDesktop) ? TEXT_DESKTOP_MAPS[onDesktop] : null,
  )

  const TheIcon = icon ? <Icon icon={icon} size={Size.SMALLER} className={iconClassName} /> : null

  const TheContent = (
    <>
      {iconPosition === IconPosition.START && TheIcon}
      <span className={textClassName}>{link?.label}</span>
      {iconPosition === IconPosition.END && TheIcon}
    </>
  )

  const TheInner =
    type === 'submit' ? (
      <div
        className={classNames(
          'group flex items-center font-bold',
          themeVariantClassName,
          variantClassName,
        )}
      >
        {TheContent}
      </div>
    ) : (
      <Link
        href={link?.href ?? ''}
        className={classNames(
          'group flex items-center font-bold',
          themeVariantClassName,
          variantClassName,
        )}
      >
        {TheContent}
      </Link>
    )

  return (
    <button
      type={type}
      className={className}
      onClick={(event) => {
        if (onClick) onClick(event)
      }}
    >
      {TheInner}
    </button>
  )
}

Button.variant = Variant

export default Button
