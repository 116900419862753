import GlobalProps from '@common/GlobalProps.interface'

import Attribution from './Attribution'
import CtaContactUs from './CtaContactUs'
import Sitemap from './Sitemap'

const Footer = ({ footer, contact, pageData }: GlobalProps) => {
  const { theme, showContactUs } = pageData.attributes

  const ContactUs = showContactUs ? <CtaContactUs theme={theme} {...contact} /> : null
  return (
    <footer className="flex flex-col items-stretch bg-gray-200">
      {ContactUs}
      {footer && <Sitemap theme={theme} footer={footer} />}
      {footer && <Attribution attribution={footer.attribution} />}
    </footer>
  )
}

export default Footer
