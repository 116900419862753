/* eslint-disable no-restricted-globals */
import Link, { LinkProps } from 'next/link'
import { usePathname } from 'next/navigation'
import React, { PropsWithChildren, useEffect, useState } from 'react'

type ActiveLinkProps = LinkProps & {
  className?: string
  activeClassName: string
}

const ActiveLink = ({
  children,
  activeClassName,
  className,
  ...props
}: PropsWithChildren<ActiveLinkProps>) => {
  const path = usePathname()
  const [computedClassName, setComputedClassName] = useState(className)

  useEffect(() => {
    if (path !== null) {
      // Dynamic route will be matched via props.as
      // Static route will be matched via props.href
      const linkPathname = new URL((props.as || props.href) as string, location.href).pathname

      // Using URL().pathname to get rid of query and hash
      const activePathname = new URL(path, location.href).pathname

      const newClassName =
        linkPathname === activePathname ? `${className} ${activeClassName}`.trim() : className

      if (newClassName !== computedClassName) {
        setComputedClassName(newClassName)
      }
    }
  }, [path, props.as, props.href, activeClassName, className, computedClassName])

  return (
    <Link className={computedClassName} {...props}>
      {children}
    </Link>
  )
}

export default ActiveLink
