import Props from '@common/Props.interface'
import { Size, Theme } from '@components/shared'
import Icon from '@icons/Icon'
import { Icons } from '@icons/index'
import classNames from '@utils/classNames'

enum Variant {
  WHITE = 0,
  LIGHT_GRAY = 1,
}

export interface RoundIconProps extends Props {
  icon?: Icons | null
  variant?: Variant
  size?: Size
}

const THEME_MAPS: Record<Theme, string> = {
  [Theme.ORANGE]: 'text-orange-500',
  [Theme.GREEN]: 'text-green-500',
}

const VARIANT_MAPS: Record<Variant, string> = {
  [Variant.WHITE]: 'bg-white',
  [Variant.LIGHT_GRAY]: 'bg-gray-200',
}

const SIZE_MAPS: Record<Size, string> = {
  [Size.SMALLER]: 'h-8 w-8',
  [Size.SMALL]: 'h-12 w-12',
  [Size.MEDIUM]: 'h-20 w-20',
  [Size.LARGE]: 'h-28 w-28',
  [Size.LARGER]: 'h-32 w-32',
}

const RoundIcon = ({
  children,
  theme,
  icon = null,
  variant = Variant.WHITE,
  size = Size.MEDIUM,
}: RoundIconProps) => {
  const themeClassName = theme !== undefined ? THEME_MAPS[theme] : null

  const smallerSize = (currentSize: Size) => {
    switch (currentSize) {
      case Size.LARGER:
        return Size.LARGE
      case Size.LARGE:
        return Size.MEDIUM
      case Size.MEDIUM:
        return Size.SMALL
      case Size.SMALL:
      default:
        return Size.SMALLER
    }
  }

  // Decrease icon size by one step
  const TheIcon = icon ? <Icon icon={icon} size={smallerSize(size)} /> : null

  return (
    <div
      className={classNames(
        'group flex items-center justify-center rounded-full',
        themeClassName,
        VARIANT_MAPS[variant],
        SIZE_MAPS[size],
      )}
    >
      {TheIcon}
      {children}
    </div>
  )
}

RoundIcon.variant = Variant

export default RoundIcon
