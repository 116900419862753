'use client'

import { Menu } from '@common/Menu.interface'
import Props from '@common/Props.interface'
import { Size, Theme } from '@components/shared'
import Icon from '@icons/Icon'
import classNames from '@utils/classNames'
import { useState } from 'react'

import SlideOver from './SlideOver'

export interface SlideOverMenuProps extends Props {
  menu: Menu
}

const THEME_MAPS: Record<Theme, string> = {
  [Theme.ORANGE]: 'text-orange-500',
  [Theme.GREEN]: 'text-green-500',
}

const SlideOverMenu = ({ className = '', menu, theme = Theme.ORANGE }: SlideOverMenuProps) => {
  const [opened, setOpened] = useState(false)
  return (
    <>
      <div
        className={`flex flex-col items-center transition-all ${
          opened ? 'rotate-180' : ''
        } ${className}`}
      >
        <button
          type="button"
          onClick={() => setOpened(!opened)}
          className={classNames(THEME_MAPS[theme])}
        >
          <Icon icon="bars" size={Size.SMALL} />
        </button>
      </div>

      {/* // FIXME: Propagate theme/variant to SlideOver */}
      <SlideOver opened={opened} menu={menu} closeHandler={() => setOpened(false)} />
    </>
  )
}

export default SlideOverMenu
